import { io } from 'socket.io-client'
import { ref } from 'vue'
import { useOneStore } from '@/stores/oneStore'
import { useRadioStore } from '@/stores/radioStore'

import errorSound from '@/assets/error.mp3'

let pingTimers = null
let pongTimeout = null
let counter = 0

let socket
let manuallyDisconnected = false
const audio = new Audio()

export const socketStatus = ref('disconnected')

export const connectSocket = () => {
  const store = useOneStore()
  const radioStore = useRadioStore()

  manuallyDisconnected = false

  socket = io(import.meta.env.VITE_APP_SOCKET_IO_SERVER, {
    path: '/socket',
    auth: {
      token: localStorage.getItem('token')
    },
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    extraHeaders: {
      header: 'socketIo'
    },
    transports: ['websocket']
  })

  socket.on('connect', () => {
    socketStatus.value = 'connected'
    startAutoPinger()
  })

  socket.on('message', (event) => {
    if (event.type === 'driverStatusUpdate') {
      store.updateDriverStatus(event)
    } else if (event.type === 'carOccupancyUpdate') {
      store.handleCarOccupancyUpdate(event)
    } else if (event.type === 'vehiclePositionUpdate') {
      store.updateVehiclePosition(event)
    } else if (event.type === 'new-order') {
      store.handleNewOrder(event)
    } else if (event.type === 'new-recording') {
      radioStore.newRecording(event)
    } else if (event.type === 'speaking') {
      radioStore.setCurrentSpeaker(event.name)
    } else if (event.type === 'stop-speaking') {
      radioStore.setCurrentSpeaker('')
    } else if (event.type === 'speaking-kick-out') {
      radioStore.speakingKickedOut(true)
      audio.src = errorSound
      audio.preload = 'auto'
      audio.oncanplaythrough = () => audio.play()
      setTimeout(() => {
        radioStore.speakingKickedOut(false)
      }, 500)
    }
  })

  socket.on('disconnect', () => {
    socketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      socket.connect()
    }
  })

  socket.on('reconnect', () => {
    stopAutoPinger()
    socketStatus.value = 'connected'
  })

  socket.on('reconnect_attempt', () => {
    socketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      socket.connect()
    }
  })

  socket.on('reconnect_error', () => {
    socketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      socket.connect()
    }
  })

  socket.on('reconnect_failed', () => {
    socketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      socket.connect()
    }
  })

  socket.on('error', () => {
    socketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      socket.connect()
    }
  })

  return socket
}

export const disconnectSocket = () => {
  manuallyDisconnected = true
  if (socket) {
    socket.disconnect()
    socketStatus.value = 'disconnected'
  }
}

export const emitEvent = (eventName, data) => {
  if (socket) {
    socket.emit(eventName, data)
  } else {
    console.error('Socket is not connected')
  }
}

export const startAutoPinger = () => {
  // Clear any existing timers
  clearInterval(pingTimers)
  clearTimeout(pongTimeout)

  // Remove any previous 'bong' listener to avoid duplications
  if (socket) {
    socket.off('bong')
  }

  // Reset the counter
  counter = 0

  // Start sending 'pang' every 10 seconds
  pingTimers = setInterval(() => {
    console.log('ping')
    socket.emit('pang')
    counter++

    // Set a timeout to reconnect if no 'bong' is received within 3 seconds
    pongTimeout = setTimeout(() => {
      if (!manuallyDisconnected && socket.connected) {
        console.log('No pong received, disconnecting and reconnecting...')
        socket.disconnect()
        socket.connect()
      }
    }, 3000) // 3 seconds to wait for 'bong'
  }, 10000) // Send 'pang' every 10 seconds

  // Listen for 'bong' response from the server
  socket.on('bong', () => {
    console.log('pong')
    counter = 0
    clearTimeout(pongTimeout) // Clear the pong timeout when 'bong' is received
  })

  console.log(counter)
}

export const stopAutoPinger = () => {
  // Clear timers when stopping the pinger
  clearInterval(pingTimers)
  clearTimeout(pongTimeout)
  if (socket) {
    socket.off('bong')
  }
}
