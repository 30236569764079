import { io } from 'socket.io-client'
import { ref } from 'vue'
// import { useOneStore } from '@/stores/oneStore'

let pingTimers = null
let pongTimeout = null
let counter = 0

let radioSocket
let manuallyDisconnected = false
export const radioSocketStatus = ref('disconnected')

export const connectRadioSocket = () => {
  // const store = useOneStore()

  manuallyDisconnected = false

  radioSocket = io(import.meta.env.VITE_APP_RADIO_SOCKET_IO_SERVER, {
    path: '/socket',
    auth: {
      token: localStorage.getItem('token')
    },
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    extraHeaders: {
      header: 'socketIo'
    },
    transports: ['websocket']
  })

  radioSocket.on('connect', () => {
    radioSocketStatus.value = 'connected'
    startAutoPinger()
  })

  radioSocket.on('disconnect', () => {
    radioSocketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      radioSocket.connect()
    }
  })

  radioSocket.on('reconnect', () => {
    startAutoPinger()
    radioSocketStatus.value = 'connected'
  })

  radioSocket.on('reconnect_attempt', () => {
    radioSocketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      radioSocket.connect()
    }
  })

  radioSocket.on('reconnect_error', () => {
    radioSocketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      radioSocket.connect()
    }
  })

  radioSocket.on('reconnect_failed', () => {
    radioSocketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      radioSocket.connect()
    }
  })

  radioSocket.on('error', () => {
    radioSocketStatus.value = 'disconnected'
    stopAutoPinger()
    if (!manuallyDisconnected) {
      radioSocket.connect()
    }
  })

  return radioSocket
}

export const disconnectRadioSocket = () => {
  manuallyDisconnected = true
  if (radioSocket) {
    stopAutoPinger()
    radioSocket.disconnect()
    radioSocketStatus.value = 'disconnected'
  }
}

export const emitRadioEvent = (eventName, data) => {
  if (radioSocket) {
    radioSocket.emit(eventName, data)
  } else {
    console.error('Radio Socket is not connected')
  }
}

export const startAutoPinger = () => {
  // Clear any existing timers
  clearInterval(pingTimers)
  clearTimeout(pongTimeout)

  // Remove any previous 'bong' listener to avoid duplications
  if (radioSocket) {
    radioSocket.off('bong')
  }

  // Reset the counter
  counter = 0

  // Start sending 'pang' every 10 seconds
  pingTimers = setInterval(() => {
    radioSocket.emit('radio-pang')
    counter++

    // If counter is below 5, set a timeout to reconnect if no 'bong' is received
    if (counter <= 3) {
      pongTimeout = setTimeout(() => {
        if (!manuallyDisconnected && radioSocketStatus.value !== 'connected') {
          console.log('No pong received, reconnecting...')
          radioSocket.connect()
        }
      }, 3000) // 3 seconds to wait for 'bong'
    } else {
      // Reset the counter after 30 seconds if no reconnection
      setTimeout(() => {
        counter = 0
      }, 30000)
    }
  }, 5000) // Send 'pang' every 10 seconds

  // Listen for 'bong' response from the server
  radioSocket.on('radio-bong', () => {
    counter = 0
    clearTimeout(pongTimeout) // Clear the pong timeout when 'bong' is received
  })
}

export const stopAutoPinger = () => {
  // Clear timers when stopping the pinger
  clearInterval(pingTimers)
  clearTimeout(pongTimeout)
  if (radioSocket) {
    radioSocket.off('bong')
  }
}
