import { acceptHMRUpdate, defineStore } from 'pinia'

export const useRadioStore = defineStore('Radio', {
  state: () => ({
    speaking: false,
    radioHistory: [],
    currentSpeaker: '',
    amIKickedOut: false,
    radioEnabled: localStorage.getItem('radioEnabled') === 'true'
  }),
  getters: {
    isRadioEnabled: (state) => state.radioEnabled,
    isSpeaking: (state) => state.speaking,
    radioMessagesHistory: (state) => state.radioHistory,
    activeSpeaker: (state) => state.currentSpeaker,
    theyKickedMeOut: (state) => state.amIKickedOut
  },
  actions: {
    toggleRadioEnabled() {
      localStorage.setItem('radioEnabled', this.radioEnabled ? 'false' : 'true')
      this.radioEnabled = !this.radioEnabled
    },
    speak(value) {
      this.speaking = value
    },
    newRecording(event) {
      this.radioHistory.push(event.message)
      if (this.radioHistory.length > 5) {
        this.radioHistory.shift()
      }
    },
    setCurrentSpeaker(speaker) {
      this.currentSpeaker = speaker
    },
    speakingKickedOut(value) {
      this.amIKickedOut = value
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRadioStore, import.meta.hot))
}
