<script setup>
import { OpusDecoder } from 'opus-decoder'
import { onMounted, computed, watch, ref, onBeforeUnmount } from 'vue'
import { useTheme } from 'vuetify'
import { hexToRgb } from '@layouts/utils'
import { useOneStore } from '@/stores/oneStore'
import { useRadioStore } from '@/stores/radioStore'
import { connectSocket, socketStatus, disconnectSocket } from '@/lib/socketIo'
import { connectRadioSocket, radioSocketStatus, disconnectRadioSocket } from '@/lib/radioSocketIo'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { global } = useTheme()
const store = useOneStore()
const radioStore = useRadioStore()

const { getMyCompanyId, getMyId, getMyName } = storeToRefs(store)
const { isRadioEnabled, isSpeaking, activeSpeaker } = storeToRefs(radioStore)

const socket = ref(null)
const radioSocket = ref(null)
const errorMessage = computed(() => t('errorMessages.socketDisconnectedPleaseRefreshPage'))
const openSocketDisconnectedSnackbar = ref(false)

const isLoggedIn = computed(() => store.isLoggedIn)

const webCodecsSupported = ref(false)

const api = import.meta.env.VITE_TAXIOS_API

onMounted(() => {
  if (isLoggedIn.value) {
    webCodecsSupported.value = 'AudioEncoder' in window
    if (webCodecsSupported.value) {
      initEncoder()
    }
    store.getInitialData().then(async () => {
      connectSocketFunction()
    })

    window.addEventListener('online', handleOnline)
    document.addEventListener('visibilitychange', handleVisibilityChange)
  }
  // setTimeout(() => {
  //   window.fcWidget.init({
  //     token: 'e7f80791-9be0-4741-8636-dd08ba47e596',
  //     host: 'warberryappss-a6e25a0fa46745b17255075.freshchat.com/v2',
  //     locale: 'sk',
  //     config: {
  //       disableNotifications: false
  //     }
  //   })
  // }, 1000)
})

onBeforeUnmount(() => {
  document.removeEventListener('visibilitychange', handleVisibilityChange)
  window.removeEventListener('online', handleOnline)
})

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    console.log('Page is visible, checking WebSocket connection...')
    if (!socket.value || socketStatus.value === 'disconnected') {
      disconnectSocket()
      disconnectRadioSocket()
      setTimeout(() => {
        connectSocketFunction()
        resetRecordingSide()
        resetReceivingSide()
      }, 500)
    } else {
      console.log('socket still connected')
      resetRecordingSide()
    }
  }
}

const handleOnline = () => {
  if (!socket.value || socketStatus.value === 'disconnected') {
    disconnectSocket()
    disconnectRadioSocket()
    setTimeout(() => {
      connectSocketFunction()
      resetRecordingSide()
      resetReceivingSide()
    }, 500)
  } else {
    console.log('socket still connected')
    resetRecordingSide()
  }
}

watch(
  () => isLoggedIn.value,
  async (newVal) => {
    if (newVal) {
      connectSocketFunction()
    } else {
      disconnectSocket()
      disconnectRadioSocket()
    }
  }
)

watch(
  () => socketStatus.value,
  (newVal) => {
    console.log('socket', newVal)
    if (isLoggedIn.value) {
      if (newVal === 'disconnected') {
        openSocketDisconnectedSnackbar.value = true
      } else {
        openSocketDisconnectedSnackbar.value = false
        console.log('socketConnected')
      }
    }
  }
)

watch(
  () => radioSocketStatus.value,
  (newVal) => {
    if (newVal === 'connected' && radioSocket?.value) {
      console.log('radio server connected')
      initSocketListeners()
    }
    if (isLoggedIn.value) {
      if (newVal === 'disconnected') {
        openSocketDisconnectedSnackbar.value = true
      } else {
        openSocketDisconnectedSnackbar.value = false
      }
    }
  }
)

async function connectSocketFunction() {
  // Check if the user is logged in, if not, stop further execution
  if (!isLoggedIn.value) {
    console.log('User is not logged in. Socket connections will not be initiated.')
    return // Exit the function early if not logged in
  }

  const JWTToken = localStorage.getItem('token')

  // Check if the main socket is either undefined or not connected
  if (!socket.value || !socket.value.connected) {
    socket.value = await connectSocket()
    socket.value.io.opts.query = { token: JWTToken }
    socket.value.query = { token: JWTToken }
    socket.value.connect()

    socket.value.on('connect', () => {
      console.log('Main socket connected')
    })

    socket.value.on('disconnect', () => {
      console.log('Main socket disconnected, attempting reconnection...')
      retrySocketConnection(socket, JWTToken)
    })
  } else {
    console.log('Main socket is already connected')
  }

  // Check if the radio socket is either undefined or not connected
  if (!radioSocket.value || !radioSocket.value.connected) {
    radioSocket.value = await connectRadioSocket()
    radioSocket.value.io.opts.query = { token: JWTToken }
    radioSocket.value.query = { token: JWTToken }
    radioSocket.value.connect()

    radioSocket.value.on('connect', () => {
      console.log('Radio socket connected')
    })

    radioSocket.value.on('disconnect', () => {
      console.log('Radio socket disconnected, attempting reconnection...')
      retrySocketConnection(radioSocket, JWTToken)
    })
  } else {
    console.log('Radio socket is already connected')
  }

  // After 30 seconds, check if the socket is still connected
  setTimeout(() => {
    const isSocketConnected = socket.value?.connected
    const isRadioSocketConnected = radioSocket.value?.connected
    console.log('isSocketConnected', isSocketConnected)
    console.log('isRadioSocketConnected', isRadioSocketConnected)
  }, 30000)
}

// Helper function for retrying socket connection on disconnect
function retrySocketConnection(socketRef, JWTToken) {
  let retryInterval = setInterval(() => {
    if (!socketRef.value?.connected) {
      console.log('Retrying connection...')
      socketRef.value.io.opts.query = { token: JWTToken }
      socketRef.value.connect()
    } else {
      clearInterval(retryInterval) // Clear retry once connected
      console.log('Socket reconnected successfully')
    }
  }, 5000) // Retry every 5 seconds
}
const SAMPLE_RATE = 24000
const CHANNELS = 1
let audioContext = null
const audioBufferQueue = []
let nextBufferTime = 0

let decoder = new OpusDecoder({
  channels: CHANNELS,
  forceStereo: false,
  sampleRate: SAMPLE_RATE
})

function initSocketListeners() {
  if (radioSocket?.value) {
    console.log('radio server listener initialized')
    radioSocket.value.on('chat-data', (e) => {
      const uint8Array = new Uint8Array(e)
      if (isRadioEnabled.value) {
        const rec = decoder.decodeFrame(uint8Array)

        if (rec.channelData && rec.channelData.length > 0 && rec.channelData[0]) {
          bufferAndPlayChunk(rec.channelData[0])
        }
      }
    })
  }
}

function initializeAudioContext() {
  if (!audioContext) {
    try {
      audioContext = new (window.AudioContext || window.webkitAudioContext)()
      nextBufferTime = audioContext.currentTime
    } catch (e) {
      console.error('Web Audio API is not supported in this browser.', e)
      alert('Web Audio API is not supported in this browser.')
    }
  }
}

function bufferAndPlayChunk(pcmChunk) {
  initializeAudioContext()
  if (audioContext) {
    audioBufferQueue.push(pcmChunk)

    if (audioBufferQueue.length >= 5) {
      scheduleBuffers()
    }
  }
}

function scheduleBuffers() {
  while (audioBufferQueue.length > 0 && audioContext) {
    const chunk = audioBufferQueue.shift()
    if (chunk) {
      const bufferSource = audioContext.createBufferSource()
      const frameCount = chunk.length
      const audioBuffer = audioContext.createBuffer(1, frameCount, SAMPLE_RATE)

      audioBuffer.getChannelData(0).set(chunk)
      bufferSource.buffer = audioBuffer
      bufferSource.connect(audioContext.destination)

      if (nextBufferTime < audioContext.currentTime) {
        nextBufferTime = audioContext.currentTime
      }
      bufferSource.start(nextBufferTime)
      const bufferDuration = frameCount / SAMPLE_RATE
      nextBufferTime += bufferDuration

      bufferSource.onended = () => {
        bufferSource.disconnect()
        bufferSource.buffer = null
      }
    }
  }
}

let accumulatedSizeBytes = 0
let lastLogTime = Date.now()
let encoder = null

watch(isSpeaking, (newVal) => {
  if (newVal === true) {
    startCapture()
  } else {
    stopCapture()
    resetRecordingSide()
  }
})

watch(activeSpeaker, (newVal) => {
  if (newVal && newVal.length > 0) {
    // Someone is speaking, receiving audio
  } else {
    // No one is speaking, reset the receiving side
    resetReceivingSide()
  }
})

function resetRecordingSide() {
  console.log('Resetting the recording side...')

  // Reset the recording context and encoder
  if (audioContext2) {
    audioContext2.close()
    audioContext2 = null
  }

  if (encoder) {
    encoder.flush()
    encoder = null
  }

  globalStream = null

  initEncoder() // Reinitialize the encoder if needed
  console.log('Recording side reset complete.')
}

function resetReceivingSide() {
  console.log('Resetting the receiving side...')

  // Close and reset the audio context
  if (audioContext) {
    audioContext.close()
    audioContext = null
  }

  decoder = new OpusDecoder({
    channels: CHANNELS,
    forceStereo: false,
    sampleRate: SAMPLE_RATE
  })
  audioBufferQueue.length = 0 // Clear any queued audio buffers
  nextBufferTime = 0

  initializeAudioContext() // Reinitialize the audio context for receiving

  console.log('Receiving side reset complete.')
}

function initEncoder() {
  const encoderInit = new AudioEncoder({
    output(chunk) {
      let newBuffer = new ArrayBuffer(chunk.byteLength)
      chunk.copyTo(newBuffer)
      accumulatedSizeBytes += chunk.byteLength

      const currentTime = Date.now()
      if (currentTime - lastLogTime >= 60000) {
        const sizeKb = accumulatedSizeBytes / 1024
        console.log(`Transferred in the last minute: ${sizeKb.toFixed(2)} KB`)

        accumulatedSizeBytes = 0
        lastLogTime = currentTime
      }

      const object = {
        companyId: getMyCompanyId.value,
        name: getMyName.value,
        userId: getMyId.value,
        data: newBuffer
      }
      radioSocket.value.emit('send-chat-data', object)
    },
    error(e) {
      console.error(e)
    }
  })

  encoderInit.configure({
    codec: 'opus',
    numberOfChannels: 1,
    sampleRate: 24000,
    bitrate: 16000,
    tuning: {}
  })

  encoder = encoderInit
}

let audioContext2 = null
let globalStream = null

const startCapture = async () => {
  stopCapture()
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    console.error('MediaDevices API or getUserMedia not supported.')
    return
  }

  try {
    const object = {
      companyId: getMyCompanyId.value,
      name: getMyName.value,
      userId: getMyId.value
    }
    radioSocket.value.emit('speaking', object)
  } catch (err) {
    console.log(err)
  }

  try {
    if (!audioContext2 || audioContext2.state === 'closed') {
      audioContext2 = new AudioContext({ sampleRate: SAMPLE_RATE })
    }

    globalStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true
      }
    })

    audioContext2 = new AudioContext({ sampleRate: SAMPLE_RATE })

    try {
      await audioContext2.audioWorklet.addModule(new URL('@/lib/radioAudioProcessor.js', import.meta.url))
    } catch (err) {
      console.log(err)
    }

    const audioWorkletNode = new AudioWorkletNode(audioContext2, 'audio-processor')
    const sourceNode = audioContext2.createMediaStreamSource(globalStream)
    sourceNode.connect(audioWorkletNode)

    const silentGain = new GainNode(audioContext2, { gain: 0 })
    audioWorkletNode.connect(silentGain)
    silentGain.connect(audioContext2.destination)

    audioWorkletNode.port.onmessage = async (event) => {
      const rawAudioFrame = event.data

      const audioBuffer = new AudioBuffer({
        length: rawAudioFrame.length,
        numberOfChannels: 1,
        sampleRate: 24000
      })

      audioBuffer.copyToChannel(rawAudioFrame, 0)
      const audioData = await convertAudioBufferToAudioData(audioBuffer)
      encoder.encode(audioData)
    }
  } catch (error) {
    if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
      console.error('Microphone access was denied. Please enable it in your browser settings.')
      alert('Microphone access was denied. Please enable it in your browser settings.')
    } else {
      console.error('Error setting up audio capture:', error)
    }
  }
}

async function convertAudioBufferToAudioData(audioBuffer) {
  return new AudioData({
    format: 'f32-planar',
    sampleRate: audioBuffer.sampleRate,
    numberOfFrames: audioBuffer.length,
    numberOfChannels: audioBuffer.numberOfChannels,
    timestamp: performance.now(),
    data: audioBuffer.getChannelData(0)
  })
}

const stopCapture = () => {
  if (audioContext2) {
    audioContext2.close()
  }

  radioSocket.value.emit('stop-speaking', {
    companyId: getMyCompanyId.value,
    name: getMyName.value,
    userId: getMyId.value
  })

  audioContext2 = null

  if (encoder) {
    encoder.flush()
  }

  if (globalStream) {
    globalStream.getTracks().forEach((track) => track.stop())
    globalStream = null
  }
}
</script>

<template>
  <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
    <div v-if="api.includes('dev')" style="position: absolute; top: 15px; right: 50%; z-index: 50505050; color: red">DEV</div>
    <VSnackbar :timeout="200000" v-model="openSocketDisconnectedSnackbar" color="error" location="top">
      <v-progress-circular indeterminate color="white" size="20" class="mr-2"></v-progress-circular>
      {{ errorMessage }}
    </VSnackbar>
    <RouterView />
  </VApp>
</template>
