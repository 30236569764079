import { createRouter, createWebHistory } from 'vue-router'
import { useOneStore } from '@/stores/oneStore'

const routes = [
  { path: '/', redirect: '/dashboard' },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '../layouts/default.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../pages/TaxiDashboard.vue')
      },
      {
        name: 'statistics',
        path: 'statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '../pages/TaxiStatistics.vue')
      },
      {
        name: 'orders',
        path: 'taxi-orders',
        component: () => import(/* webpackChunkName: "orders" */ '../pages/TaxiOrders.vue')
      },
      {
        name: 'map',
        path: 'taxi-map',
        component: () => import(/* webpackChunkName: "map" */ '../pages/TaxiMap.vue')
      },
      {
        name: 'drivers',
        path: 'taxi-drivers',
        component: () => import(/* webpackChunkName: "drivers" */ '../pages/TaxiDrivers.vue')
      },
      {
        name: 'taxiosSentinel',
        path: 'taxios-sentinel',
        component: () => import(/* webpackChunkName: "vehicles" */ '../pages/TaxiosSentinel.vue')
      },
      {
        name: 'vehicles',
        path: 'taxi-vehicles',
        component: () => import(/* webpackChunkName: "vehicles" */ '../pages/TaxiVehicles.vue')
      },
      {
        name: 'phones',
        path: 'taxi-phones',
        component: () => import(/* webpackChunkName: "phones" */ '../pages/TaxiPhones.vue')
      },
      {
        name: 'directory',
        path: 'taxi-phone-directory',
        component: () => import(/* webpackChunkName: "directory" */ '../pages/TaxiPhoneDirectory.vue')
      },
      {
        name: 'switchboard',
        path: 'taxi-switchboard',
        component: () => import(/* webpackChunkName: "switchboard" */ '../pages/TaxiSwitchBoard.vue')
      },
      {
        name: 'notifications',
        path: 'taxi-notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '../pages/TaxiNotifications.vue')
      },
      {
        name: 'fares',
        path: 'fares',
        component: () => import(/* webpackChunkName: "fares" */ '../pages/TaxiFares.vue')
      },
      {
        name: 'settings',
        path: '/settings',
        component: () => import(/* webpackChunkName: "settings" */ '../pages/TaxiSettings.vue')
      },
      {
        name: 'taxiosCustomerSettings',
        path: '/taxios-customer-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../pages/TaxiosCustomerAppSettings.vue')
      },
      {
        name: 'taxiosDriverSettings',
        path: '/taxios-driver-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../pages/TaxiosDriverSettings.vue')
      },
      {
        name: 'taxiosVRP2',
        path: '/taxios-vrp-2-sk',
        component: () => import(/* webpackChunkName: "settings" */ '../pages/TaxiosVRP2.vue')
      },
      {
        name: 'taxiosFareSettings',
        path: '/taxios-fare-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../pages/TaxiosFareSettings.vue')
      },
      {
        name: 'help',
        path: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../pages/TaxiHelp.vue')
      },
      {
        name: 'logout',
        path: 'logout',
        component: () => import(/* webpackChunkName: "logout" */ '../pages/TaxiLogout.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-blank" */ '../layouts/blank.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../pages/TaxiLogin.vue')
      },
      {
        path: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../pages/register.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "not-found" */ '../pages/[...all].vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useOneStore()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.isLoggedIn) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

export default router
