// src/lib/preventDoubleTapZoom.js
export function preventDoubleTapZoom() {
  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    (event) => {
      const now = new Date().getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )

  document.querySelectorAll('a, button').forEach((element) => {
    element.addEventListener('touchstart', (event) => {
      event.preventDefault()
      event.target.click()
    })
  })
}
