import axios from 'axios'
import { useOneStore } from '@/stores/oneStore'

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_TAXIOS_API}/api/`,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (config.method === 'post' && !(config.data instanceof FormData)) {
      config.data = {
        ...config.data,
        appUUID: import.meta.env.VITE_APP_UUID
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const store = useOneStore()
      store.logout()
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
