import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import '@core/scss/template/index.scss'
import '@layouts/styles/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import translations from './locales/index.js'
import { createI18n } from 'vue-i18n'

import { preventDoubleTapZoom } from '@/lib/preventDoubleTapZoom.js'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

import Vue3Toasity from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

loadFonts()

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: translations
})

// Create vue app
const app = createApp(App)

// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(Vue3Toasity, { autoClose: 4000 })

app.component('VueDatePicker', VueDatePicker)
app.component('EasyDataTable', Vue3EasyDataTable)

// Mount vue app
app.mount('#app')

preventDoubleTapZoom()
